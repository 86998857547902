import { Component, Input } from '@angular/core';
import { ManualEngineAllocationRequestStatus } from '../../cash-requests/enums';
import { MarketOrderStatus } from '../../market-orders/enums';

@Component({
    selector: 'app-status-tag',
    template: ` <p-tag class="uppercase text-sm" [severity]="mapOrderStatusToSeverity(status)" [value]="status!" /> `,
})
export class StatusTagComponent {
    @Input() status: ManualEngineAllocationRequestStatus | MarketOrderStatus | null = null;

    mapOrderStatusToSeverity(
        orderStatus: ManualEngineAllocationRequestStatus | MarketOrderStatus | null,
    ): 'success' | 'secondary' | 'info' | 'warning' | 'danger' | 'contrast' | undefined {
        switch (orderStatus) {
            case ManualEngineAllocationRequestStatus.COMPLETED:
                return 'contrast';
            case ManualEngineAllocationRequestStatus.COMPLETED_PENDING_CONFIRMATION:
                return 'warning';
            case ManualEngineAllocationRequestStatus.STARTED:
                return 'info';
            case ManualEngineAllocationRequestStatus.STARTED_PENDING_CONFIRMATION:
                return 'warning';
            case ManualEngineAllocationRequestStatus.CREATED:
                return 'success';
            case MarketOrderStatus.COMPLETED:
                return 'contrast';
            case MarketOrderStatus.COMPLETED_PENDING_CONFIRMATION:
                return 'warning';
            case MarketOrderStatus.STARTED:
                return 'info';
            case MarketOrderStatus.STARTED_PENDING_CONFIRMATION:
                return 'warning';
            case MarketOrderStatus.CREATED:
                return 'success';
            case MarketOrderStatus.PROCESSED:
                return 'info';
            case MarketOrderStatus.PROCESSED_PENDING_CONFIRMATION:
                return 'warning';
            case MarketOrderStatus.FAILED:
                return 'danger';
            case MarketOrderStatus.FAILED_PENDING_CONFIRMATION:
                return 'danger';
            default:
                return undefined;
        }
    }
}
